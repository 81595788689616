import React, {useEffect, useState } from "react";
import cs from "./showcase.module.css";
import {Link, useLocation } from "react-router-dom";
import axios from "axios";

export default function Template(){
let [updateHead, setUpdateHead] = useState('');
    let [updateTemp, setUpdateTemp] = useState('');
    let [updateStats, setUpdateStats] = useState('');
    let [updateStatimg, setUpdateStatimg] = useState('');
    let [lightBox, setLightBox] = useState('');
    let [closeUpdate, setCloseUpdate] = useState(false);
    let uselocation = useLocation();
    let currentpathCategory = uselocation.pathname.split('/')[3];
    let currentpathId = uselocation.pathname.split('/')[2];
    console.log(currentpathId);
    console.log(currentpathCategory.toLowerCase());
    let currentpathCategory1 = currentpathCategory.toLowerCase();
    const [mainData, setMainData]= useState([]);
    const [tempData, setTempData]= useState([]);
    useEffect(()=>{
        const fetchData = async ()=>{
          try{
            const response = await axios.get(`https://campaigntemplate.com/index.php?table=${currentpathCategory}contents`);
            setTempData(response.data);
          }catch(error){
            console.log(error);
          }
        };
    
        fetchData();
      },[]);
    
    // eslint-disable-next-line
    useEffect(()=>{
        const fetchData = async ()=>{
          try{
            const response = await axios.get(`https://campaigntemplate.com/index.php?table=${currentpathCategory}`);
            setMainData(response.data);
          }catch(error){
            console.log(error);
          }
        };
    
        fetchData();
      },[]);
    let currentData = mainData.filter((v)=>v.id === currentpathId)[0];
    let logo = currentData ? currentData.logo : '';
    let analy1 = currentData ? currentData.analy1 : '';
    let analy2 = currentData ? currentData.analy2 : '';
    let currentTempData = tempData.filter((v)=>v.template_id === currentpathId);
    let firstData = currentTempData.length > 0 ? currentTempData[0] : '';
    // console.log(head1);
    let lightboxContent = document.querySelector("#lightboxContent");
    let closeBtn = ()=>{
        setCloseUpdate(false);
        lightboxContent.style.height = "350px";
    };
    let plusBtn = ()=>{
        let height = lightboxContent.clientHeight;
        lightboxContent.style.height = (height + 20) + "px";
    }
    
    let minusBtn = ()=>{
        let height = lightboxContent.clientHeight;
        lightboxContent.style.height = (height - 20) + "px";
    }
    return(
        <div className="max-w-[100%] relative md:h-full h-auto p-4 flex-wrap flex justify-center gap-y-5 gap-x-7 bg-[#f5f9fc]">
            <div className={`lg:max-w-[260px] bg-white w-[100%] flex justify-between flex-col items-center md:h-screen h-auto lg:sticky top-5 md:rounded-lg rounded-md ${cs.btnSec}`}>
                <div className="flex w-[100%] justify-center flex-col items-start">
                    <div className="w-[100%] h-auto flex justify-start items-start border-b-2 pb-6 border-blue-200 rounded-md">
                        <img src={logo} className="h-[50px] w-auto" alt="logo" />
                        {/* <span className="text-blue-700 text-[14px]">Top list</span> */}
                    </div>
                    {
                        currentTempData.map((v,i)=>{
                            // console.log(v.date);
                            return(
                            <div key={i} className="w-full">
                            <div onClick={()=>{
                                setUpdateHead(v.head);
                                setUpdateTemp(v.temp);
                                setUpdateStats(v.id);
                                setUpdateStatimg(v.stats);
                                console.log(v.id)
                            }}className={`${updateStats === v.id ? `${cs.button1}` : `${cs.button}`} md:w-[200px] w-full`}>
                                <p className={`${updateStats === v.id ? `${cs.redColor}` : ``} text-ellipsis whitespace-nowrap w-[90%] overflow-hidden text-left`}>{v.head}</p><p className={`${updateStats === v.id ? `${cs.arrowup}` : `${cs.arrowdown}`}`}>&#x2B9D;</p>
                            </div>
                            <div >
                            </div>
                            </div>
                            )
                        })
                    }
                </div>
                <div className={cs.btnBack}>
                <Link to={'/'}><div className={cs.button2}>
                    <p className={`text-left ${cs.arrowleft}`}>&#x2B9D;</p><p className="">Back</p>
                    </div>
                </Link>
                <Link to={`/edit/${currentpathId}/${currentpathCategory}`}>
                        <div className={`${cs.button2} w-[40%] bg-red-400`}>
                            <p className="text-sm">Edit</p>
                            <p className={`text-left rotate-90 ${cs.arrowright}`}>&#x2B9D;</p>
                        </div>
                    </Link>
                </div>
            </div>
            <div className={`max-w-[620px] w-[100%] bg-white md:my-[30px] md:rounded-lg rounded-md ${cs.btnSec}`}>
                <div className="w-full bg-gray-100 p-6 rounded-lg border-l-4 border-indigo-600">
                    {/*  eslint-disable-next-line */}
                <h1 className="text-left text-gray-600 md:text-xl text-md font-bold md:text-ellipsis md:whitespace-nowrap w-[95%] overflow-hidden">{updateHead == "" ? firstData.head : updateHead}</h1>
                <h2 className="text-left text-gray-400 text-md mt-2">Emailer | {currentpathCategory}</h2>
                {/*  eslint-disable-next-line */}
                </div>
                {/* eslint-disable-next-line*/}
                <img onClick={()=>{ setLightBox(updateStatimg == "" ? firstData.stats : updateStatimg); setCloseUpdate(true)}} className="rounded shadow-md mt-6 w-full cursor-zoom-in" src={updateStatimg == "" ? firstData.stats : updateStatimg} alt=""/>                
                <img src={analy1} onClick={()=>{ setLightBox(analy1); setCloseUpdate(true)}} className="shadow-md mt-6 w-full cursor-zoom-in" alt="Analy1" />
                <img src={analy2} onClick={()=>{ setLightBox(analy2); setCloseUpdate(true)}} className="shadow-md mt-6 w-full cursor-zoom-in" alt="Analy2" />
                
            </div>
            <div className={`max-w-[355px] w-[100%] md:h-screen h-auto md:rounded-lg rounded-md sticky top-5 bg-white ${cs.btnSec}`}>
                <h1 className="text-left text-black font-bold text-lg mb-6">Email Template</h1>
                {/*  eslint-disable-next-line */}
                <img onClick={()=>{ setLightBox(updateTemp == "" ? firstData.temp : updateTemp); setCloseUpdate(true)}} className="rounded shadow-md cursor-zoom-in" src={updateTemp == "" ? firstData.temp : updateTemp} alt=""/>
            </div>
            {/*  eslint-disable-next-line */}
            <div className={`fixed w-full h-full flex bg-black bg-opacity-90 z-10 top-0 right-0 justify-center items-center ${closeUpdate == true ? "block" : "hidden"}`}>
                <div className="relative w-full h-[100%] box-border pt-4 flex overflow-y-auto justify-center flex-col items-center">
                    <img src={lightBox} id="lightboxContent" style={{height:350}} className="w-auto" alt=""/>
                    <div className="flex justify-end fixed bottom-4 right-8 items-center w-full gap-4">
                        <button onClick={minusBtn} className=" text-white bg-red-800 text-[20px] rounded-sm outline-none border-none cursor-pointer px-3">&#45;</button>
                        <button onClick={plusBtn} className=" text-white bg-green-800 text-[20px] rounded-sm outline-none border-none cursor-pointer px-3">&#43;</button>
                    </div>
                    <button onClick={closeBtn} className="fixed top-4 right-8 bg-white text-blue-950 text-lg rounded-sm outline-none border-none cursor-pointer px-2">&#10799;</button>
                </div>
            </div>
        </div>
    )
}
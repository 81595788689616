import React, { useEffect, useState } from "react";
import axios from "axios";
import css from './showcase.module.css';
import logo from "./images/LakeB2BLogo.png"
import searchIcon from "./images/search.png"
import { Link, useLocation } from "react-router-dom";
import All from "./All.jsx";
import Healthcare from './Healthcare';
import Education from './Education';
import Technology from './Technology';
import Business from './Business';
import Ecommerce from './Ecommerce';
import Electronics from './Electronics';
import Accounting from './Accounting';
import AdhesivesandSealants from './AdhesivesandSealants';
import Advertising from './Advertising';
import AdvertisingAgencies from './AdvertisingAgencies';
import AdvertisingNec from './AdvertisingNec';
import Automotive from './Automotive';
import BookPublishing from './BookPublishing';
import BusinessServices from './BusinessServices';
import Chemical from './Chemical';
import ChemicalManufacturing from './ChemicalManufacturing';
import ComputerSoftware from './ComputerSoftware';
import Construction from './Construction';
import Educational from './Educational';
import EducationTraining from './EducationTraining';
import ELearning from './ELearning';
import ElectricalIndustrialApparatus from './ElectricalIndustrialApparatus';
import ElectronicComponentsNec from './ElectronicComponentsNec';
import ElectronicPartsandEquipmentNec from './ElectronicPartsandEquipmentNec';
import Finance from './Finance';
import FinancialServices from './FinancialServices';
import FreightTransportationArrangement from './FreightTransportationArrangement';
import Government from './Government';
import HealthandAlliedServicesNec from './HealthandAlliedServicesNec';
import HomeHealthCareServices from './HomeHealthCareServices';
import Hospitality from './Hospitality';
import HospitalsClinics from './HospitalsClinics';
import HumanResources from './HumanResources';
import InformationRetrievalServices from './InformationRetrievalServices';
import InformationServices from './InformationServices';
import InformationTechnology from './InformationTechnology';
import Insurance from './Insurance';
import InsuranceAgentsBrokersandService from './InsuranceAgentsBrokersandService';
import InvestmentBankBrokerage from './InvestmentBankBrokerage';
import ManagementConsulting from './ManagementConsulting';
import ManagementConsultingServices from './ManagementConsultingServices';
import Manufacturing from './Manufacturing';
import MarketingAdvertising from './MarketingAdvertising';
import MarketingAdvertisingPR from './MarketingAdvertisingPR';
import MediaEntertainment from './MediaEntertainment';
import NewsDealersandNewsstands from './NewsDealersandNewsstands';
import Newspapers from './Newspapers';
import Nonprofit from './Nonprofit';
import Outsourcing from './Outsourcing';
import Pharmaceutical from './Pharmaceutical';
import PrintingandPublishingIndustry from './PrintingandPublishingIndustry';
import Publishing from './Publishing';
import Services from './Services';
import Software from './Software';
import TechnologyConsulting from './TechnologyConsulting';
import TelecommunicationIndustry from './TelecommunicationIndustry';
import Tools from './Tools';
import Training from './Training';
export default function Showcase(){
    let [category, setCategory]= useState('');
    let [search, setSearch]= useState('');
    let [searchdata, setSearchdata]= useState('');
    let [dataFound, setDataFound] = useState(true);
    let [hamIcon, setHamIcon]= useState(false);
    useEffect(()=>{
    },)
    let hamclick = ()=>{
        // eslint-disable-next-line
        if(hamIcon == false){
            setHamIcon(true)
        }else{
            setHamIcon(false)
        }
    }
    const [dataCat, setDataCat] = useState([]);

        useEffect(() => {
        const fetchData = async () => {
        try {
        const response = await axios.get('https://campaigntemplate.com/index.php?table=catname');
        setDataCat(response.data); // Append new data to existing data
        } catch (error) {
        console.error(error);
        }
        };

        fetchData();
        
        }, []);
        let categories = dataCat.map((item, index) => (
        <option key={item.id} value={item.catData} onChange={()=>setCategory(item.catData)} className="text-[14px]">{item.catTitle}</option>
        ));
    

    const renderContent = () => {
        switch (category) {
        case 'Healthcare':
        return <Healthcare searchdata={searchdata} setDataFound={setDataFound} />;
        case 'Education':
        return <Education searchdata={searchdata} setDataFound={setDataFound} />;
        case 'Technology':
        return <Technology searchdata={searchdata} setDataFound={setDataFound} />;
        case 'Business':
        return <Business searchdata={searchdata} setDataFound={setDataFound} />;
        case 'Ecommerce':
        return <Ecommerce searchdata={searchdata} setDataFound={setDataFound} />;
        case 'Electronics':
        return <Electronics searchdata={searchdata} setDataFound={setDataFound} />;
        case 'Accounting':
        return <Accounting searchdata={searchdata} setDataFound={setDataFound} />;
        case 'AdhesivesandSealants':
      return <AdhesivesandSealants searchdata={searchdata} setDataFound={setDataFound} />
      case 'Advertising':
      return <Advertising searchdata={searchdata} setDataFound={setDataFound} />
      case 'AdvertisingAgencies':
      return <AdvertisingAgencies searchdata={searchdata} setDataFound={setDataFound} />
      case 'AdvertisingNec':
      return <AdvertisingNec searchdata={searchdata} setDataFound={setDataFound} />
      case 'Automotive':
      return <Automotive searchdata={searchdata} setDataFound={setDataFound} />
      case 'BookPublishing':
      return <BookPublishing searchdata={searchdata} setDataFound={setDataFound} />
      case 'BusinessServices':
      return <BusinessServices searchdata={searchdata} setDataFound={setDataFound} />
      case 'Chemical':
      return <Chemical searchdata={searchdata} setDataFound={setDataFound} />
      case 'ChemicalManufacturing':
      return <ChemicalManufacturing searchdata={searchdata} setDataFound={setDataFound} />
      case 'ComputerSoftware':
      return <ComputerSoftware searchdata={searchdata} setDataFound={setDataFound} />
      case 'Construction':
      return <Construction searchdata={searchdata} setDataFound={setDataFound} />
      case 'Educational':
      return <Educational searchdata={searchdata} setDataFound={setDataFound} />
      case 'EducationTraining':
      return <EducationTraining searchdata={searchdata} setDataFound={setDataFound} />
      case 'ELearning':
      return <ELearning searchdata={searchdata} setDataFound={setDataFound} />
      case 'ElectricalIndustrialApparatus':
      return <ElectricalIndustrialApparatus searchdata={searchdata} setDataFound={setDataFound} />
      case 'ElectronicComponentsNec':
      return <ElectronicComponentsNec searchdata={searchdata} setDataFound={setDataFound} />
      case 'ElectronicPartsandEquipmentNec':
      return <ElectronicPartsandEquipmentNec searchdata={searchdata} setDataFound={setDataFound} />
      case 'Finance':
      return <Finance searchdata={searchdata} setDataFound={setDataFound} />
      case 'FinancialServices':
      return <FinancialServices searchdata={searchdata} setDataFound={setDataFound} />
      case 'FreightTransportationArrangement':
      return <FreightTransportationArrangement searchdata={searchdata} setDataFound={setDataFound} />
      case 'Government':
      return <Government searchdata={searchdata} setDataFound={setDataFound} />
      case 'HealthandAlliedServicesNec':
      return <HealthandAlliedServicesNec searchdata={searchdata} setDataFound={setDataFound} />
      case 'HomeHealthCareServices':
      return <HomeHealthCareServices searchdata={searchdata} setDataFound={setDataFound} />
      case 'Hospitality':
      return <Hospitality searchdata={searchdata} setDataFound={setDataFound} />
      case 'HospitalsClinics':
      return <HospitalsClinics searchdata={searchdata} setDataFound={setDataFound} />
      case 'HumanResources':
      return <HumanResources searchdata={searchdata} setDataFound={setDataFound} />
      case 'InformationRetrievalServices':
      return <InformationRetrievalServices searchdata={searchdata} setDataFound={setDataFound} />
      case 'InformationServices':
      return <InformationServices searchdata={searchdata} setDataFound={setDataFound} />
      case 'InformationTechnology':
      return <InformationTechnology searchdata={searchdata} setDataFound={setDataFound} />
      case 'Insurance':
      return <Insurance searchdata={searchdata} setDataFound={setDataFound} />
      case 'InsuranceAgentsBrokersandService':
      return <InsuranceAgentsBrokersandService searchdata={searchdata} setDataFound={setDataFound} />
      case 'InvestmentBankBrokerage':
      return <InvestmentBankBrokerage searchdata={searchdata} setDataFound={setDataFound} />
      case 'ManagementConsulting':
      return <ManagementConsulting searchdata={searchdata} setDataFound={setDataFound} />
      case 'ManagementConsultingServices':
      return <ManagementConsultingServices searchdata={searchdata} setDataFound={setDataFound} />
      case 'Manufacturing':
      return <Manufacturing searchdata={searchdata} setDataFound={setDataFound} />
      case 'MarketingAdvertising':
      return <MarketingAdvertising searchdata={searchdata} setDataFound={setDataFound} />
      case 'MarketingAdvertisingPR':
      return <MarketingAdvertisingPR searchdata={searchdata} setDataFound={setDataFound} />
      case 'MediaEntertainment':
      return <MediaEntertainment searchdata={searchdata} setDataFound={setDataFound} />
      case 'NewsDealersandNewsstands':
      return <NewsDealersandNewsstands searchdata={searchdata} setDataFound={setDataFound} />
      case 'Newspapers':
      return <Newspapers searchdata={searchdata} setDataFound={setDataFound} />
      case 'Nonprofit':
      return <Nonprofit searchdata={searchdata} setDataFound={setDataFound} />
      case 'Outsourcing':
      return <Outsourcing searchdata={searchdata} setDataFound={setDataFound} />
      case 'Pharmaceutical':
      return <Pharmaceutical searchdata={searchdata} setDataFound={setDataFound} />
      case 'PrintingandPublishingIndustry':
      return <PrintingandPublishingIndustry searchdata={searchdata} setDataFound={setDataFound} />
      case 'Publishing':
      return <Publishing searchdata={searchdata} setDataFound={setDataFound} />
      case 'Services':
      return <Services searchdata={searchdata} setDataFound={setDataFound} />
      case 'Software':
      return <Software searchdata={searchdata} setDataFound={setDataFound} />
      case 'TechnologyConsulting':
      return <TechnologyConsulting searchdata={searchdata} setDataFound={setDataFound} />
      case 'TelecommunicationIndustry':
      return <TelecommunicationIndustry searchdata={searchdata} setDataFound={setDataFound} />
      case 'Tools':
      return <Tools searchdata={searchdata} setDataFound={setDataFound} />
      case 'Training':
      return <Training searchdata={searchdata} setDataFound={setDataFound} />
        default:
        return <All searchdata={searchdata} setDataFound={setDataFound} />;
        }
        };
    let allproducts = ()=>{
        setCategory('');
        setSearchdata('');
        setSearch('');
        setHamIcon(false);
    }
    let allReset = ()=>{
        let catReset = document.getElementById("Category");
        catReset.value = "All";
        setCategory('');
        setSearchdata('');
        setSearch('');
        setHamIcon(false);
    }
    let searchTemp = (event) => {
        event.preventDefault();
        setSearchdata(search.toLowerCase());
        setHamIcon(false);
        
        };
    return(
        <div className="max-w-[100%] max-h-[100%] bg-slate-200 box-border flex md:justify-between justify-center flex-wrap">
           {/*  eslint-disable-next-line */}
            <div className={`w-[22%] h-screen bg-white sticky top-0 box-border shadow ${hamIcon == false ? css.catSec : css.catSecA} ${css.Bshadow}`}>
                <div className="w-full h-auto border-b-2 border-yellow-300 p-[35px]">
                    <img src={logo} alt="Campaign Showcase Logo"/>
                    {/* <h1 className="text-white text-3xl text-left font-bold">Campaign</h1>
                    <h2 className="text-white text-2xl text-left">Showcase</h2> */}
                </div>
                <div className={`w-full p-[40px] h-auto box-border ${css.scrollbarr}`}>
                    <form onSubmit={searchTemp} className="w-full flex justify-between mt-2  box-border rounded-sm cursor-pointer border-[1px] bg-transparent border-indigo-700">
                        <input type="text" value={search} onChange={(e)=>setSearch(e.target.value)} placeholder="Search temp..." className="outline-none border-none pl-2 py-2 text-indigo-700 bg-transparent w-[70%] "/>
                        <button className="outline-none border-none w-[20%] text-indigo-700 bg-indigo-700 flex justify-center items-center"><img src={searchIcon} style={{width:20}} alt=""/></button>
                    </form>
                    <div className="w-full h-auto flex justify-between items-center gap-3">
                    <select  defaultValue={'DEFAULT'} onChange={(e)=>{setCategory(e.target.value); setSearchdata(''); setSearch(''); setHamIcon(false)}} className="w-full mt-3 py-2 px-1 text-left box-border rounded-sm cursor-pointer border-[1px] border-indigo-700 bg-indigo-700 text-white" name="Category" id="Category">
                    <option value="All" onClick={allproducts}>All Templates</option>
                        {categories}
                    </select>
                    <h1 value="" onClick={allReset} className="bg-indigo-700 mt-3 px-3 py-[6px] text-center text-[18px] box-border rounded-sm cursor-pointer hover:bg-blue-950 border-indigo-700 text-white" >&#10227;</h1>
                    </div>
                </div>
                
            </div>
            <div className={`w-[78%] h-full md:p-[40px] pb-5 md:mt-0 mt-10 relative flex justify-center flex-wrap gap-8 box-border`}>
            {dataFound ? renderContent() : <h1>No Data Found For {searchdata}</h1>}
                   <div className={css.hamburger}>
                    {/*  eslint-disable-next-line */}
                    <button onClick={hamclick} className="text-blue-950 p-1 cursor-pointer"><img src={hamIcon == false ? "https://campurl.com/2024/Mar/Showcase/images/menu.png" : 'https://campurl.com/2024/Mar/Showcase/images/cancel.png'} className="w-[26px]" alt="Menu" /> </button>
                </div>       
            </div>
        </div>
    )
}
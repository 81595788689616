import React from 'react'
import errorImg from "./images/404.jpg"
import { Link } from 'react-router-dom';
const ErrorPage = () => {
  return (
    <div className='bg-white h-full w-full flex justify-center items-center flex-col'>
      <div className='w-full h-auto flex justify-center items-center'>
        <img src={errorImg} className='md:w-[500px] w-full' alt="error404" />
      </div>
      <div className='w-full h-[30%] flex justify-center items-start'>
        <Link to={'/'}> <button className=' bg-emerald-700 px-4 py-3 text-white rounded-full'>Go back to homepage</button></Link>
      </div >
    </div>
  )
}

export default ErrorPage;

import React, {useEffect, useState} from 'react';
import axios from "axios";
import css from './showcase.module.css';
import { Link, useLocation } from "react-router-dom";
const InvestmentBankBrokerage = ({searchdata}) => {
  const [card, setCard] = useState([]);
  const [dataFound, setDataFound] = useState(true);

  useEffect(()=>{
    const fetchData = async ()=>{
      try{
        const response = await axios.get('https://campaigntemplate.com/index.php?table=investmentbankbrokerage');
        setCard(response.data);
      }catch(error){
        console.log(error);
      }
    };

    fetchData();
  },[]);

  useEffect(() => {
    let filteredData = card.filter(item => item.title.toLowerCase().includes(searchdata));
    setDataFound(filteredData.length > 0);
    }, [card, searchdata]);

  const cardData = card.filter(items =>{
    return(searchdata == undefined ? items : (items.title.toLowerCase().includes(searchdata) || items.category.toLowerCase().includes(searchdata)))
  }).map((item, index)=>{
    return (
      <div key={index} className={`w-[270px] h-[280px] bg-white flex flex-col rounded-md relative box-border ${css.cards}`}>
                <div className="w-[100%] h-[100%] p-[20px]">
                    <img className="w-[230px] h-[180px]" src={item.thumbnail} alt=""/>
                    <h1 className="text-blue-950 font-[700] text-left text-lg mt-8">{item.title}</h1>
                    {/* <h1 className="text-blue-950 text-left text-l">{v.year} | {v.category}</h1> */}
                </div>
                <div className={`w-[100%] h-full  hidden ${css.carditem}`}>
                    <div className="absolute top-0 w-[100%] h-full bg-black bg-opacity-50 box-content rounded-md flex justify-center items-center">
                        <button className="bg-blue-600 box-content text-gray-100 font-[500] rounded-full py-2 px-6 hover:bg-transparent hover:bg-black"><Link to={`/Template/${item.id}/${item.category}/${item.title.replaceAll(" ", "-")}`}>Explore</Link></button>
                    </div>
                </div>
            </div>
    )
  });
  return (
    <>
         {cardData}
    </>
  )
}

export default InvestmentBankBrokerage;

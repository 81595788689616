import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Edit = () => {
    const [formData, setFormData] = useState({ content: [] });
    const [cat, setCat] = useState("");
    const [dataCat, setDataCat] = useState([]);
    const navigate = useNavigate();
    const { id, category } = useParams(); // Get ID and category from URL

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://campaigntemplate.com/index.php?table=${category}&id=${id}`);
                const data = response.data[0];
                setFormData(data);
                setCat(data.category);
            } catch (error) {
                console.error('Error fetching data for editing:', error);
            }
        };

        const fetchCategories = async () => {
            try {
                const response = await axios.get('https://campaigntemplate.com/index.php?table=catname');
                setDataCat(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchData();
        fetchCategories();
    }, [id, category]);

    const handleChange = (e) => {
        const oldData = { ...formData };
        const inputValue = e.target.value;
        const inputName = e.target.name;

        if (inputName.startsWith('head') || inputName.startsWith('stats') || inputName.startsWith('temp')) {
            const index = inputName.replace(/\D/g, '');

            if (!oldData.content[index]) {
                oldData.content[index] = {};
            }
            oldData.content[index][inputName.replace(/\d+/g, '')] = inputValue;
        } else {
            oldData[inputName] = inputValue;
        }

        setFormData(oldData);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.put(`https://campaigntemplate.com/index.php?id=${id}`, {
                ...formData,
                category: cat // Sending category selected in dropdown as 'category' field
            });
            toast.success('Template Updated Successfully!!');
            navigate(`/template/${id}/${category}`); // Redirect to the template view page
        } catch (error) {
            console.error('Error updating form:', error.response ? error.response.data : error.message);
        }
    };

    const industry = dataCat.map((item) => (
        <option key={item.id} value={item.catData} className="text-[14px]">{item.catTitle}</option>
    ));

    return (
        <div className='w-full h-full bg-[#f0f8ff] flex justify-center items-center flex-col p-7 relative'>
            <div className='w-auto h-auto bg-[#ffffff] flex justify-center items-center flex-col p-6 rounded-lg shadow-md'>
                <h1 className='text-2xl font-bold text-blue-700'>Edit Template</h1>
                <form onSubmit={handleSubmit} className='lg:w-[600px] w-full h-auto flex justify-center items-center flex-col mt-5'>
                    <label htmlFor="title" className='w-full text-left text-gray-700'>Title Name:</label>
                    <input type="text" id="title" name="title" value={formData.title || ''} onChange={handleChange} className='w-full text-left mt-1 border' required />
                    <div className='w-full h-auto flex justify-between items-center md:flex-row mt-6 flex-col gap-4'>
                        <div className='w-full h-auto'>
                            <label htmlFor="category" className='w-full text-left block text-gray-700'>Select Industry:</label>
                            <select name="category" value={cat} id="category" onChange={(event) => { setCat(event.target.value.toLowerCase()); handleChange(event) }} className='w-full text-left mt-1 border p-1 bg-white' required>
                                <option value="" disabled>Industry</option>
                                {industry}
                            </select>
                        </div>
                    </div>
                    <div className='w-full h-auto flex justify-between items-center md:flex-row flex-col mt-6 gap-4'>
                        <div className='md:w-1/2 w-full h-auto'>
                            <label htmlFor="logo" className='w-full text-left mt-3 block text-gray-700'>Logo Url:</label>
                            <input type="text" id="logo" name="logo" value={formData.logo || ''} onChange={handleChange} className='w-full text-left mt-1 border' required />
                        </div>
                        <div className='md:w-1/2 w-full h-auto'>
                            <label htmlFor="thumbnail" className='w-full text-left mt-3 block text-gray-700'>Thumbnail Url:</label>
                            <input type="text" id="thumbnail" name="thumbnail" value={formData.thumbnail || ''} onChange={handleChange} className='w-full text-left mt-1 border' required />
                        </div>
                    </div>
                    <div className='w-full h-auto flex justify-between items-center md:flex-row flex-col mt-6 gap-4'>
                        <div className='md:w-1/2 w-full h-auto'>
                            <label htmlFor="analy1" className='w-full text-left mt-3 block text-gray-700'>Analytic Url-1:</label>
                            <input type="text" id="analy1" name="analy1" value={formData.analy1 || ''} onChange={handleChange} className='w-full text-left mt-1 border' required />
                        </div>
                        <div className='md:w-1/2 w-full h-auto'>
                            <label htmlFor="analy2" className='w-full text-left mt-3 block text-gray-700'>Analytic Url-2:</label>
                            <input type="text" id="analy2" name="analy2" value={formData.analy2 || ''} onChange={handleChange} className='w-full text-left mt-1 border' required />
                        </div>
                    </div>
                    <div className='w-full h-auto flex justify-between items-center flex-col mt-6 gap-4'>
                        <div className='w-full h-auto flex justify-end items-end flex-col gap-4'>
                            <button className='px-2 py-1 bg-green-700 text-white w-full rounded-sm' type='submit'>Update</button>
                        </div>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
}

export default Edit;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MLogo from "./images/Logo1.png";
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Insert = () => {
    const [formData, setFormData] = useState({ content: [] });
    const [cat, setCat] = useState("");
    const [dataCat, setDataCat] = useState([]);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const oldData = { ...formData };
        const inputValue = e.target.value;
        const inputName = e.target.name;

        if (inputName.startsWith('head') || inputName.startsWith('stats') || inputName.startsWith('temp')) {
            const index = inputName.replace(/\D/g, '');

            if (!oldData.content[index]) {
                oldData.content[index] = {};
            }
            oldData.content[index][inputName.replace(/\d+/g, '')] = inputValue;
        } else {
            oldData[inputName] = inputValue;
        }

        setFormData(oldData);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const response = await axios.post('https://campaigntemplate.com/index.php', {
                ...formData,
                category: cat // Sending category selected in dropdown as 'category' field
            });
            toast.success('Template Added Successfully!!');
        } catch (error) {
            console.error('Error submitting form:', error.response ? error.response.data : error.message);
        }
    };
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://campaigntemplate.com/index.php?table=catname');
                setDataCat(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchData();
    }, []);

    const industry = dataCat.map((item, index) => (
        <option key={item.id} value={item.catData} className="text-[14px]">{item.catTitle}</option>
    ));

    const [copies, setCopies] = useState(1);

    const addCopy = () => {
        setCopies(copies + 1);
    };

    return (
        <div className='w-full h-full bg-[#f0f8ff] flex justify-center items-center flex-col p-7 relative'>
            <div className='w-auto h-auto bg-[#ffffff] flex justify-center items-center flex-col p-6 rounded-lg shadow-md'>
                <h1 className='text-2xl font-bold text-blue-700'>Add New Template</h1>
                <form onSubmit={handleSubmit} className='lg:w-[600px] w-full h-auto flex justify-center items-center flex-col mt-5'>
                    <label htmlFor="title" className='w-full text-left text-gray-700'>Title Name:</label>
                    <input type="text" id="title" name="title" onChange={handleChange} className='w-full text-left mt-1 border' required />
                    <div className='w-full h-auto flex justify-between items-center md:flex-row mt-6 flex-col gap-4'>
                        <div className='w-full h-auto'>
                            <label htmlFor="category" className='w-full text-left block text-gray-700'>Select Industry:</label>
                            <select name="category" defaultValue={'DEFAULT'} id="category" onChange={(event) => {setCat(event.target.value.toLowerCase()); handleChange(event) }} className='w-full text-left mt-1 border p-1 bg-white' required>
                                <option value="DEFAULT" disabled>Industry</option>
                                {industry}
                            </select>
                        </div>
                    </div>
                    <div className='w-full h-auto flex justify-between items-center md:flex-row flex-col mt-6 gap-4'>
                        <div className='md:w-1/2 w-full h-auto'>
                            <label htmlFor="logo" className='w-full text-left mt-3 block text-gray-700'>Logo Url:</label>
                            <input type="text" id="logo" name="logo" onChange={handleChange} className='w-full text-left mt-1 border' required />
                        </div>
                        <div className='md:w-1/2 w-full h-auto'>
                            <label htmlFor="thumbnail" className='w-full text-left mt-3 block text-gray-700'>Thumbnail Url:</label>
                            <input type="text" id="thumbnail" onChange={handleChange} name="thumbnail" className='w-full text-left mt-1 border' required />
                        </div>
                    </div>
                    <div className='w-full h-auto flex justify-between items-center md:flex-row flex-col mt-6 gap-4'>
                        <div className='md:w-1/2 w-full h-auto'>
                            <label htmlFor="analy1" className='w-full text-left mt-3 block text-gray-700'>Analytic Url-1:</label>
                            <input type="text" id="analy1" name="analy1" onChange={handleChange} className='w-full text-left mt-1 border' required />
                        </div>
                        <div className='md:w-1/2 w-full h-auto'>
                            <label htmlFor="analy2" className='w-full text-left mt-3 block text-gray-700'>Analytic Url-2:</label>
                            <input type="text" id="analy2" name="analy2" onChange={handleChange} className='w-full text-left mt-1 border' required />
                        </div>
                    </div>
                    <div className='w-full h-auto flex justify-between items-center flex-col mt-6 gap-4'>
                        <h2 className='text-lg text-gray-600 text-left font-bold w-full'>Contents:</h2>
                        {[...Array(copies)].map((_, index) => (
                            <div key={index} className='w-full h-auto flex justify-between items-center flex-col border-b-2 pb-6'>
                                <div className='w-full h-auto flex justify-center items-center flex-col'>
                                    <div className='w-full h-auto flex justify-between items-center md:flex-row flex-col gap-4'>
                                        <div className='w-full h-auto'>
                                            <label htmlFor={`head${index + 1}`} className='w-full text-left mt-3 block text-gray-700'>Heading {index + 1}:</label>
                                            <input type="text" id={`head${index + 1}`} name={`head${index}`} onChange={handleChange} className='w-full text-left mt-1 border' required />
                                        </div>
                                    </div>
                                    <div className='w-full h-auto flex justify-between items-center md:flex-row flex-col gap-4'>
                                        <div className='md:w-1/2 w-full h-auto'>
                                            <label htmlFor={`stats${index + 1}`} className='w-full text-left mt-3 block text-gray-700'>Static Image Url {index + 1}:</label>
                                            <input type="text" id={`stats${index + 1}`} name={`stats${index}`} onChange={handleChange} className='w-full text-left mt-1 border' required />
                                        </div>
                                        <div className='md:w-1/2 w-full h-auto'>
                                            <label htmlFor={`temp${index + 1}`} className='w-full text-left mt-3 block text-gray-700'>Template Url {index + 1}:</label>
                                            <input type="text" id={`temp${index + 1}`} name={`temp${index}`} onChange={handleChange} className='w-full text-left mt-1 border' required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className='w-full h-auto flex justify-end items-end flex-col gap-4'>
                            <button onClick={addCopy} className='px-2 py-1 bg-blue-700 rounded-sm text-white'>Add More +</button>
                            <button className='px-2 py-1 bg-green-700 text-white w-full rounded-sm' type='submit'>Submit</button>
                        </div>
                    </div>
                </form>
            </div>
            <ToastContainer />
            <div className='w-auto h-auto mt-7 flex justify-center items-center'>
                <h1>Go to <Link to={"/"} className='text-blue-600'>homepage</Link></h1>
            </div>
            <div className='h-auto absolute top-5 left-5'>
                <Link to={"/"}><img src={MLogo} className='w-[200px] h-auto' alt="Campaign Showcase" /></Link>
            </div>
        </div>
    );
}

export default Insert;

import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import Showcase from './components/showcase';
import Template from './components/template';
import ErrorPage from './components/Error';
import Insert from './components/Insert';
import Healthcare from './components/Healthcare';
import Education from './components/Education';
import Template1 from './components/template1';
import All from './components/All';
import Edit from './components/Edit';

function App() {
  let allrountes = createBrowserRouter([
    {
     path:'/',
      element:<Showcase />
    },
    {
      path:'/Template/:title',
      element:<Template/>
    },
    {
      path:'/template/:id/:category/:title',   
      element:<Template1/>
    },
    {
      path:'/Insert',
      element:<Insert/>
    },
    {
      path:'/edit/:id/:category',
      element:<Edit/>
    },
    {
      path:'/*',
      element:<ErrorPage/>
    }
  ])
  return (
    <div className="App">
      <RouterProvider router={allrountes} />
    </div>
  );
}

export default App;

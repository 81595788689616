import React from 'react'
import Healthcare from './Healthcare';
import Education from './Education';
import Technology from './Technology';
import Business from './Business';
import Ecommerce from './Ecommerce';
import Electronics from './Electronics';
import Accounting from './Accounting';
import AdhesivesandSealants from './AdhesivesandSealants';
import Advertising from './Advertising';
import AdvertisingAgencies from './AdvertisingAgencies';
import AdvertisingNec from './AdvertisingNec';
import Automotive from './Automotive';
import BookPublishing from './BookPublishing';
import BusinessServices from './BusinessServices';
import Chemical from './Chemical';
import ChemicalManufacturing from './ChemicalManufacturing';
import ComputerSoftware from './ComputerSoftware';
import Construction from './Construction';
import Educational from './Educational';
import EducationTraining from './EducationTraining';
import ELearning from './ELearning';
import ElectricalIndustrialApparatus from './ElectricalIndustrialApparatus';
import ElectronicComponentsNec from './ElectronicComponentsNec';
import ElectronicPartsandEquipmentNec from './ElectronicPartsandEquipmentNec';
import Finance from './Finance';
import FinancialServices from './FinancialServices';
import FreightTransportationArrangement from './FreightTransportationArrangement';
import Government from './Government';
import HealthandAlliedServicesNec from './HealthandAlliedServicesNec';
import HomeHealthCareServices from './HomeHealthCareServices';
import Hospitality from './Hospitality';
import HospitalsClinics from './HospitalsClinics';
import HumanResources from './HumanResources';
import InformationRetrievalServices from './InformationRetrievalServices';
import InformationServices from './InformationServices';
import InformationTechnology from './InformationTechnology';
import Insurance from './Insurance';
import InsuranceAgentsBrokersandService from './InsuranceAgentsBrokersandService';
import InvestmentBankBrokerage from './InvestmentBankBrokerage';
import ManagementConsulting from './ManagementConsulting';
import ManagementConsultingServices from './ManagementConsultingServices';
import Manufacturing from './Manufacturing';
import MarketingAdvertising from './MarketingAdvertising';
import MarketingAdvertisingPR from './MarketingAdvertisingPR';
import MediaEntertainment from './MediaEntertainment';
import NewsDealersandNewsstands from './NewsDealersandNewsstands';
import Newspapers from './Newspapers';
import Nonprofit from './Nonprofit';
import Outsourcing from './Outsourcing';
import Pharmaceutical from './Pharmaceutical';
import PrintingandPublishingIndustry from './PrintingandPublishingIndustry';
import Publishing from './Publishing';
import Services from './Services';
import Software from './Software';
import TechnologyConsulting from './TechnologyConsulting';
import TelecommunicationIndustry from './TelecommunicationIndustry';
import Tools from './Tools';
import Training from './Training';

const All = ({searchdata}) => {
  return (
    <div className='w-full h-auto relative flex justify-center flex-wrap gap-8 box-border'>
      <Healthcare searchdata={searchdata} />
      <Education searchdata={searchdata} />
      <Technology searchdata={searchdata} />
      <Business searchdata={searchdata} />
      <Ecommerce searchdata={searchdata} />
      <Electronics searchdata={searchdata} />
      <Accounting searchdata={searchdata} />
      <AdhesivesandSealants searchdata={searchdata} />
      <Advertising searchdata={searchdata} />
      <AdvertisingAgencies searchdata={searchdata} />
      <AdvertisingNec searchdata={searchdata} />
      <Automotive searchdata={searchdata} />
      <BookPublishing searchdata={searchdata} />
      <BusinessServices searchdata={searchdata} />
      <Chemical searchdata={searchdata} />
      <ChemicalManufacturing searchdata={searchdata} />
      <ComputerSoftware searchdata={searchdata} />
      <Construction searchdata={searchdata} />
      <Educational searchdata={searchdata} />
      <EducationTraining searchdata={searchdata} />
      <ELearning searchdata={searchdata} />
      <ElectricalIndustrialApparatus searchdata={searchdata} />
      <ElectronicComponentsNec searchdata={searchdata} />
      <ElectronicPartsandEquipmentNec searchdata={searchdata} />
      <Finance searchdata={searchdata} />
      <FinancialServices searchdata={searchdata} />
      <FreightTransportationArrangement searchdata={searchdata} />
      <Government searchdata={searchdata} />
      <HealthandAlliedServicesNec searchdata={searchdata} />
      <HomeHealthCareServices searchdata={searchdata} />
      <Hospitality searchdata={searchdata} />
      <HospitalsClinics searchdata={searchdata} />
      <HumanResources searchdata={searchdata} />
      <InformationRetrievalServices searchdata={searchdata} />
      <InformationServices searchdata={searchdata} />
      <InformationTechnology searchdata={searchdata} />
      <Insurance searchdata={searchdata} />
      <InsuranceAgentsBrokersandService searchdata={searchdata} />
      <InvestmentBankBrokerage searchdata={searchdata} />
      <ManagementConsulting searchdata={searchdata} />
      <ManagementConsultingServices searchdata={searchdata} />
      <Manufacturing searchdata={searchdata} />
      <MarketingAdvertising searchdata={searchdata} />
      <MarketingAdvertisingPR searchdata={searchdata} />
      <MediaEntertainment searchdata={searchdata} />
      <NewsDealersandNewsstands searchdata={searchdata} />
      <Newspapers searchdata={searchdata} />
      <Nonprofit searchdata={searchdata} />
      <Outsourcing searchdata={searchdata} />
      <Pharmaceutical searchdata={searchdata} />
      <PrintingandPublishingIndustry searchdata={searchdata} />
      <Publishing searchdata={searchdata} />
      <Services searchdata={searchdata} />
      <Software searchdata={searchdata} />
      <TechnologyConsulting searchdata={searchdata} />
      <TelecommunicationIndustry searchdata={searchdata} />
      <Tools searchdata={searchdata} />
      <Training searchdata={searchdata} />
    </div>
  )
}

export default All;
